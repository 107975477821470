.addSAModal {
  font-family: Prompt;
  padding: 0;
  border-radius: 0.75em;
  max-width: 640px;
  margin: auto;
  margin-top: 8vh;
  max-height: 80vh;
  background-color: #fff;
  overflow-y: scroll;
  outline: none;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.3);
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
}

.inputBox {
  border: 2px solid #dddddd;
  border-radius: 10px;
  textarea {
    padding: 0;
    font-weight: 400;
  }
}
